const getDefaultState = () => {
  return {
    loading: false,
    tariffs: [],
  }
}

const state = getDefaultState

const mutations = {
  SET_TARIFFS(state, tariffs) {
    state.tariffs = tariffs
  },
}
const actions = {
  async initTariffsStore({ dispatch }) {
    await dispatch('fetchTariffs')
  },
  async fetchTariffs({ commit, state }, query) {
    state.loading = true
    let response = await this._vm.$api.tariffs.getTariffs(query)
    state.loading = false
    if (response.status !== 200) {
      this._vm.$showNotification(
        'error',
        'Hubo un error al recuperar las quejas'
      )
      return
    }
    // this.pagination.total = response.data.num_total_elements
    // this.pagination.current = response.data.current_page

    const tariffs = response.data.result.map((item) => ({
      ...item,
      key: item.codigo_registro,
    }))
    commit('SET_TARIFFS', tariffs)
  },
  async createTariff({ dispatch, getters }, payload) {
    let { formData, formatType } = payload
    let response
    if (getters.isDeposit(formatType)) {
      response = await this._vm.$api.tariffs.createDeposit(formData)
    } else if (getters.isCreditCard(formatType)) {
      response = await this._vm.$api.tariffs.createCreditCard(formData)
    } else {
      response = await this._vm.$api.tariffs.createCredit(formData)
    }

    if (response.status !== 201) {
      return { success: false, ...response }
    }
    this._vm.$showNotification('success', 'Tarifario creado correctamente')
    dispatch('fetchTariffs')
    return { success: true, ...response }
  },
  async fetchTariff({ getters }, payload) {
    const { id, formatType } = payload
    let response
    if (getters.isDeposit(formatType)) {
      response = await this._vm.$api.tariffs.getDeposit(id)
    } else if (getters.isCreditCard(formatType)) {
      response = await this._vm.$api.tariffs.getCreditCard(id)
    } else {
      response = await this._vm.$api.tariffs.getCredit(id)
    }
    if (response.status !== 200) {
      this._vm.$showNotification('error', response.data)
      return
    }
    return response
  },
  async updateTariff({ dispatch, getters }, payload) {
    let { formData, formatType, id } = payload
    let response
    if (getters.isDeposit(formatType)) {
      response = await this._vm.$api.tariffs.updateDeposit(id, formData)
    } else if (getters.isCreditCard(formatType)) {
      response = await this._vm.$api.tariffs.updateCreditCard(id, formData)
    } else {
      response = await this._vm.$api.tariffs.updateCredit(id, formData)
    }

    if (response.status !== 200) {
      return { success: false, ...response }
    }
    this._vm.$showNotification('success', 'Tarifario actualizado correctamente')
    dispatch('fetchTariffs')
    return { success: true, ...response }
  },
  async disableTariff({ dispatch, getters }, payload) {
    let { formData, formatType, id } = payload

    let response
    if (getters.isDeposit(formatType)) {
      response = await this._vm.$api.tariffs.disableDeposit(id, formData)
    } else if (getters.isCreditCard(formatType)) {
      response = await this._vm.$api.tariffs.disableCreditCard(id, formData)
    } else {
      response = await this._vm.$api.tariffs.disableCredit(id, formData)
    }

    if (response.status !== 200) {
      return { success: false, ...response }
    }
    this._vm.$showNotification(
      'success',
      'Tarifario dehabilitado correctamente'
    )
    dispatch('fetchTariffs')
    return { success: true, ...response }
  },
}
const getters = {
  isDeposit: () => {
    return (id) => id === 1
  },
  isCreditCard: () => {
    return (id) => id === 2
  },
  isCredit() {
    return (id) => id === 3
  },
}

const tariffs = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default tariffs
