import Vue from 'vue'
import store from './../store'
import VueRouter from 'vue-router'
import { getAuth } from 'firebase/auth'
// Modules
import loginRouter from './modules/login.router'
import dashboardRouter from './modules/dashboard'
Vue.use(VueRouter)

const routes = [
  ...loginRouter,
  ...dashboardRouter,
  {
    path: '/',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
const checkModuleAccess = (route) => {
  const modules = store.getters['session/getModules']
  if (
    route.path === '/dashboard' ||
    route.path === '/dashboard/notifications' ||
    route.path === '/dashboard/profile'
  )
    return true
  const access = modules.some(
    (mod) => mod.path !== '/dashboard' && route.path.includes(mod.path)
  )
  return access
}

router.beforeEach(async (to, from, next) => {
  const user = getAuth().currentUser

  if (!store.getters['session/isLoggedIn']) {
    await store.dispatch('session/loadUser', user)
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user) {
      checkModuleAccess(to) ? next() : next('/')
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
