<template>
  <button
    class="py-2 px-3 border-2 text-sm transition"
    :type="type"
    :class="classObject"
  >
    <b class="flex justify-center items-center gap-2">
      <a-icon v-if="loading" type="loading" />
      <slot></slot>
    </b>
  </button>
</template>

<script>
export default {
  props: [
    'type',
    'invert',
    'danger',
    'success',
    'disable',
    'loading',
    'block',
    'styles',
    'round',
  ],
  computed: {
    classObject: function () {
      // return;
      let classes = [
        'border-secondary',
        'bg-secondary',
        'text-white',
        'hover:bg-gray-400',
        'hover:text-white',
        'hover:border-gray-400',
      ]
      if (this.disable) {
        classes = [
          'border-gray-400',
          'bg-gray-400',
          'text-white',
          'pointer-events-none',
        ]
      } else if (this.invert && this.styles === 'secondary') {
        classes = [
          'border-secondary',
          'bg-white',
          'text-secondary',
          'hover:bg-gray-400',
          'hover:text-white',
          'hover:border-gray-400',
        ]
      } else if (this.danger && this.invert) {
        classes = [
          'border-red-600',
          'bg-white',
          'text-red-600',
          'hover:bg-red-600',
          'hover:text-white',
        ]
      } else if (this.danger) {
        classes = [
          'border-red-600',
          'bg-red-600',
          'text-white',
          'hover:bg-red-700',
          'hover:border-red-700',
          'hover:text-white',
        ]
      } else if (this.success) {
        classes = [
          'border-green-600',
          'bg-green-600',
          'text-white',
          'hover:bg-green-700',
          'hover:border-green-700',
          'hover:text-white',
        ]
      }
      if (this.styles === 'tertiary') {
        classes = [
          'border-tertiary',
          'bg-tertiary',
          'text-white',
          'hover:bg-gray-500',
          'hover:text-white',
        ]
      }
      if (this.block) {
        classes.push('w-full')
      }
      if (this.round) {
        classes.push('rounded-full')
      } else {
        classes.push('rounded-lg')
      }
      return classes
    },
  },
}
</script>

<style></style>
