export default [
  {
    path: '/login',
    component: () => import('@/layouts/clean.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/login/'),
      },
    ],
  },
  {
    path: '/recovery',
    component: () => import('@/layouts/clean.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/login/recovery.vue'),
        meta: {
          breadcrumb: [
            { label: 'Inicio', link: '/' },
            { label: 'Restablecer contraseña' },
          ],
        },
      },
      {
        path: 'restore',
        component: () => import('@/views/login/recovery.vue'),
        meta: {
          breadcrumb: [
            { label: 'Inicio', link: '/' },
            { label: 'Restablecer contraseña' },
          ],
        },
      },
    ],
  },
]
