const url = '/api/tariffsfc'
export default (axios) => ({
  getTariffs(params) {
    return axios
      .get(`${url}?format=all`, { params })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getDeposit(params) {
    return axios
      .get(`${url}?format=deposito&codigo_registro=${params}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getCreditCard(params) {
    return axios
      .get(`${url}?format=tarjeta_credito&codigo_registro=${params}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getCredit(params) {
    return axios
      .get(`${url}?format=credito&codigo_registro=${params}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  createDeposit(data) {
    return axios
      .post(`${url}?format=deposito`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  createCreditCard(data) {
    return axios
      .post(`${url}?format=tarjeta_credito`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  createCredit(data) {
    return axios
      .post(`${url}?format=credito`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateDeposit(id, data) {
    return axios
      .put(`${url}?format=deposito&codigo_registro=${id}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateCreditCard(id, data) {
    return axios
      .put(`${url}?format=tarjeta_credito&codigo_registro=${id}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateCredit(id, data) {
    return axios
      .put(`${url}?format=credito&codigo_registro=${id}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  disableDeposit(id, data) {
    return axios
      .delete(`${url}?format=deposito&codigo_registro=${id}`, { data })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  disableCreditCard(id, data) {
    return axios
      .delete(`${url}?format=tarjeta_credito&codigo_registro=${id}`, { data })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  disableCredit(id, data) {
    return axios
      .delete(`${url}?format=credito&codigo_registro=${id}`, { data })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getTariffsMassiveTemplates() {
    return axios
      .get(`${url}/massive`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateTariffsMassiveTemplates(format, data) {
    return axios
      .post(`${url}/massive?format=${format}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
})
