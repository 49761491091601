<template>
  <div class="w-full mt-5 flex flex-col border-0 p-0">
    <label
      :for="id"
      class="mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <a-select
      show-search
      option-filter-prop="children"
      :class="{ 'border-red-500': invalid }"
      :filter-option="filterOption"
      :value="value"
      @change="handleChange"
    >
      <a-select-option value="" class="text-gray-400 font-normal">
        Seleccionar
      </a-select-option>
      <a-select-option
        v-for="option in selectOptions"
        :value="option.id"
        :key="option.id"
      >
        {{ option.name }}
      </a-select-option>
    </a-select>
    <p v-if="invalid" class="mt-2 text-left text-xs text-red-500">
      Este campo es obligatorio
    </p>
  </div>
</template>

<script>
export default {
  props: ['id', 'selectOptions', 'label', 'required', 'value'],
  data() {
    return {
      invalid: false,
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid
      }
    },
  },
  watch: {
    value: function (value) {
      if (value == '' && this.required) {
        this.invalid = true
      } else {
        this.invalid = false
      }
    },
  },
}
</script>

<style></style>
