const getDefaultState = () => {
  return {
    optionsPersonType: [],
    optionsIdentificationType: [],
    optionsCountries: [],
    optionsDepartments: [],
    optionsCity: [],
    optionsGender: [],
    optionsChannel: [],
    optionsProducts: [],
    optionsReasons: [],
    optionsControlEntity: [],
    optionsConditions: [],
    optionsStates: [],
    optionsCompanies: [],
    optionsRoles: [],
    optionsAcceptance: [],
    optionsRectification: [],
    optionsDesist: [],
    optionsAdmission: [],
    optionsStatus: [],
    optionsFavorability: [],
    optionsSectors: [],
    // Tariffs Options
    optionsTariffFormats: [],
    optionsTariffChannel: [],
    optionsDefaultCredit: [],
    optionsDepositProduct: [],
    optionsDigitalOpening: [],
    optionsFranchise: [],
    optionsFreeService: [],
    optionsSavingAccount: [],
    optionsDebitCard: [],
    optionsIncome: [],
    optionsObservationManagmentFeeDeposit: [],
    optionsObservationManagmentFeeCreditCard: [],
    optionsObservationDeposit: [],
    optionsObservationCreditCard: [],
    optionsObservationCredit: [],
    optionsPopulationGroup: [],
    optionsQuota: [],
    optionsOperationOrService: [],
    optionsOperationOrServiceCredit: [],
    optionsOperationOrServiceCreditCard: [],
    optionsInsuranceEntities: [],
    optionsYN: [
      {
        id: 1,
        name: 'Si',
      },
      {
        id: 2,
        name: 'No',
      },
    ],
  }
}

const state = getDefaultState

const mutations = {
  setFormOptions(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key]
    })
  },
  setComplaintOptions(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key]
    })
  },
  setDashboardOptions(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key]
    })
  },
  setTariffsOptions(state, payload) {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key]
    })
  },
}
const actions = {
  initFormStore({ dispatch }) {
    dispatch('loadFormOptions')
    dispatch('loadComplaintOptions')
    dispatch('loadTariffsOptions')
    dispatch('loadDashboardOptions')
  },
  // Load all form options for USER REGISTRATION
  loadFormOptions({ commit, dispatch }) {
    let baseUrl = this._vm.$api.defaults.baseURL
    let countries = dispatch('loadOptions', { url: baseUrl + '/api/country' })
    let identificationType = dispatch('loadOptions', {
      url: baseUrl + '/api/documenttype',
    })
    let personType = dispatch('loadOptions', {
      url: baseUrl + '/api/persontype',
    })
    let departments = dispatch('loadOptions', {
      url: baseUrl + '/api/department',
    })
    let roles = dispatch('loadOptions', { url: baseUrl + '/api/roles' })

    Promise.allSettled([
      personType,
      identificationType,
      countries,
      departments,
      roles,
    ])
      .then((response) => {
        commit('setFormOptions', {
          optionsPersonType: response[0].value,
          optionsIdentificationType: response[1].value,
          optionsCountries: response[2].value,
          optionsDepartments: response[3].value,
          optionsRoles: response[4].value,
        })
      })
      .catch((e) => console.log(e))
  },
  // Load all form options for COMPLAINTS registration with TOKEN
  loadComplaintOptions({ commit, dispatch }) {
    let channel = dispatch('loadOptions', { url: '/api/channel', auth: true })
    let product = dispatch('loadOptions', { url: '/api/product', auth: true })
    let reason = dispatch('loadOptions', { url: '/api/reason', auth: true })
    let controlEntity = dispatch('loadOptions', {
      url: '/api/controlentity',
      auth: true,
    })
    let conditions = dispatch('loadOptions', {
      url: '/api/specialcondition',
      auth: true,
    })
    let states = dispatch('loadOptions', {
      url: '/api/complaintstatus',
      auth: true,
    })
    let gender = dispatch('loadOptions', { url: '/api/gender', auth: true })

    Promise.allSettled([
      channel,
      product,
      reason,
      controlEntity,
      conditions,
      states,
      gender,
    ])
      .then((response) => {
        commit('setComplaintOptions', {
          optionsChannel: response[0].value,
          optionsProducts: response[1].value,
          optionsReasons: response[2].value,
          optionsControlEntity: response[3].value,
          optionsConditions: response[4].value,
          optionsStates: response[5].value,
          optionsGender: response[6].value,
        })
      })
      .catch((error) => console.log(error.response))
  },
  // Load all form options for TARIFFS registration with TOKEN
  loadTariffsOptions({ commit, dispatch }) {
    let tariffChannel = dispatch('loadOptions', {
      url: '/api/tariff/channel?format=1',
      auth: true,
    })
    let defaultCredit = dispatch('loadOptions', {
      url: '/api/tariff/defaultcredit?format=3',
      auth: true,
    })
    let depositProduct = dispatch('loadOptions', {
      url: '/api/tariff/depositproducts?format=1',
      auth: true,
    })
    let digitalOpening = dispatch('loadOptions', {
      url: '/api/tariff/digitalopening?format=1',
      auth: true,
    })
    let franchise = dispatch('loadOptions', {
      url: '/api/tariff/franchise?format=2',
      auth: true,
    })
    let freeService = dispatch('loadOptions', {
      url: '/api/tariff/freeservice?format=1',
      auth: true,
    })
    let income = dispatch('loadOptions', {
      url: '/api/tariff/income?format=1',
      auth: true,
    })
    let observationManagmentFeeDeposit = dispatch('loadOptions', {
      url: '/api/tariff/observationmanagementfee?format=1',
      auth: true,
    })
    let observationManagmentFeeCreditCard = dispatch('loadOptions', {
      url: '/api/tariff/observationmanagementfee?format=2',
      auth: true,
    })
    let observationDeposit = dispatch('loadOptions', {
      url: '/api/tariff/observation?format=1',
      auth: true,
    })
    let observationCreditCard = dispatch('loadOptions', {
      url: '/api/tariff/observation?format=2',
      auth: true,
    })
    let observationCredit = dispatch('loadOptions', {
      url: '/api/tariff/observation?format=3',
      auth: true,
    })

    let populationGroup = dispatch('loadOptions', {
      url: '/api/tariff/populationgroup?format=1',
      auth: true,
    })
    let quota = dispatch('loadOptions', {
      url: '/api/tariff/quota?format=2',
      auth: true,
    })
    let operationOrService = dispatch('loadOptions', {
      url: '/api/tariff/operationorservice?format=1',
      auth: true,
    })
    let operationOrServiceCreditCard = dispatch('loadOptions', {
      url: '/api/tariff/operationorservice?format=2',
      auth: true,
    })
    let operationOrServiceCredit = dispatch('loadOptions', {
      url: '/api/tariff/operationorservice?format=3',
      auth: true,
    })
    let tariffFormat = dispatch('loadOptions', {
      url: '/api/tariff/formats',
      auth: true,
    })
    let savingAccount = dispatch('loadOptions', {
      url: '/api/tariff/freeservice/savingaccount?format=1',
      auth: true,
    })
    let debitCard = dispatch('loadOptions', {
      url: '/api/tariff/freeservice/debitcard?format=1',
      auth: true,
    })
    let insuranceEntities = dispatch('loadOptions', {
      url: '/api/tariff/insuranceentities',
      auth: true,
    })

    Promise.allSettled([
      tariffChannel,
      defaultCredit,
      depositProduct,
      digitalOpening,
      franchise,
      freeService,
      income,
      observationManagmentFeeDeposit,
      observationDeposit,
      populationGroup,
      quota,
      operationOrService,
      tariffFormat,
      savingAccount,
      debitCard,
      insuranceEntities,
      operationOrServiceCredit,
      observationCreditCard,
      observationCredit,
      operationOrServiceCreditCard,
      observationManagmentFeeCreditCard,
    ])
      .then((response) => {
        commit('setTariffsOptions', {
          optionsTariffChannel: response[0].value,
          optionsDefaultCredit: response[1].value,
          optionsDepositProduct: response[2].value,
          optionsDigitalOpening: response[3].value,
          optionsFranchise: response[4].value,
          optionsFreeService: response[5].value,
          optionsIncome: response[6].value,
          optionsObservationManagmentFeeDeposit: response[7].value,
          optionsObservationDeposit: response[8].value,
          optionsPopulationGroup: response[9].value,
          optionsQuota: response[10].value,
          optionsOperationOrService: response[11].value,
          optionsTariffFormats: response[12].value,
          optionsSavingAccount: response[13].value,
          optionsDebitCard: response[14].value,
          optionsInsuranceEntities: response[15].value,
          optionsOperationOrServiceCredit: response[16].value,
          optionsObservationCreditCard: response[17].value,
          optionsObservationCredit: response[18].value,
          optionsOperationOrServiceCreditCard: response[19].value,
          optionsObservationManagmentFeeCreditCard: response[20].value,
        })
      })
      .catch((error) => console.log(error.response))
  },
  // Load all form options for DASHBOARD
  loadDashboardOptions({ commit, dispatch }) {
    let acceptance = dispatch('loadOptions', {
      url: '/api/acceptance',
      auth: true,
    })
    let rectification = dispatch('loadOptions', {
      url: '/api/rectification',
      auth: true,
    })
    let desist = dispatch('loadOptions', { url: '/api/desist', auth: true })
    let admission = dispatch('loadOptions', {
      url: '/api/admission',
      auth: true,
    })
    let status = dispatch('loadOptions', {
      url: '/api/complaintstatus',
      auth: true,
    })
    let favorability = dispatch('loadOptions', {
      url: '/api/favorability',
      auth: true,
    })
    let sectors = dispatch('loadOptions', {
      url: '/api/companies/sectors',
      auth: true,
    })

    Promise.allSettled([
      acceptance,
      rectification,
      desist,
      admission,
      status,
      favorability,
      sectors,
    ])
      .then((response) => {
        commit('setDashboardOptions', {
          optionsAcceptance: response[0].value,
          optionsRectification: response[1].value,
          optionsDesist: response[2].value,
          optionsAdmission: response[3].value,
          optionsStatus: response[4].value,
          optionsFavorability: response[5].value,
          optionsSectors: response[6].value,
        })
      })
      .catch((error) => console.log(error.response))
  },

  // Load municipalities options by id department
  async loadMunicipalitiesOptions({ dispatch }, payload) {
    let url = `/api/departments/${payload.department}/municipalities`
    let municipalities = dispatch('loadOptions', { url, auth: true })
    return municipalities.then((response) => {
      let optionsMunicipalities = response
      return optionsMunicipalities
    })
  },
  // Load reasons options by id company
  loadReasonsOptions({ dispatch }, payload) {
    let url = `/api/reason/company/?id_company=${payload.company}`
    let reasons = dispatch('loadOptions', { url, auth: true })
    return reasons.then((response) => {
      return response
    })
  },
  // Load products options by id company
  loadProductsOptions({ dispatch }, payload) {
    let url = `/api/product/company/?id_company=${payload.company}`
    let reasons = dispatch('loadOptions', { url, auth: true })
    return reasons.then((response) => {
      return response
    })
  },
  // Load options with pagination
  async loadOptions(context, payload) {
    try {
      let values = []
      while (payload.url) {
        let url = payload.url
        url = '/api' + url.split('/api')[1]
        const response = await this._vm.$api.axios.get(url)
        const { result, next } = response.data.data
        if (!result) return
        values.push(...result)
        payload.url = next
      }

      return values
    } catch (e) {
      console.log(e)
      return []
    }
  },
}

const form = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default form
