import Vue from 'vue'
import BaseInput from '@/components/UI/BaseInput/BaseInput'
import BaseDate from '@/components/UI/BaseInput/BaseDate'
import BasePassword from '@/components/UI/BaseInput/BasePassword'
import BaseNumber from '@/components/UI/BaseInput/BaseNumber'
import BaseTextarea from '@/components/UI/BaseInput/BaseTextarea'
import BaseRadio from '@/components/UI/BaseInput/BaseRadio'
import BaseSelect from '@/components/UI/BaseInput/BaseSelect'
import BaseButton from '@/components/UI/BaseButton'
import Button from '@/components/UI/Button'
import BaseRouterLink from '@/components/UI/BaseRouterLink'
import AccordionList from '@/components/UI/Accordion/AccordionList'
import DragDrop from '@/components/UI/DragDrop'
import Modal from '@/components/UI/Modal'
import Wrapper from '@/components/UI/Wrapper'
import SwitchButton from '@/components/UI/SwitchButton'
import BaseSelectCustom from '@/components/UI/BaseInput/BaseSelectCustom'
import BaseDownload from '@/components/UI/BaseInput/BaseDownload'
import BaseInputCustom from '@/components/UI/BaseInput/BaseInputCustom'
import DragDropExcel from '@/components/UI/DragDropExcel'
import BaseSelectNumber from '@/components/UI/BaseInput/BaseSelectNumber'

Vue.component('BaseInput', BaseInput)
Vue.component('BaseDate', BaseDate)
Vue.component('BasePassword', BasePassword)
Vue.component('BaseNumber', BaseNumber)
Vue.component('BaseTextarea', BaseTextarea)
Vue.component('BaseRadio', BaseRadio)
Vue.component('BaseSelect', BaseSelect)
Vue.component('BaseButton', BaseButton)
Vue.component('Button', Button)
Vue.component('BaseRouterLink', BaseRouterLink)
Vue.component('AccordionList', AccordionList)
Vue.component('DragDrop', DragDrop)
Vue.component('Modal', Modal)
Vue.component('Wrapper', Wrapper)
Vue.component('SwitchButton', SwitchButton)
Vue.component('BaseSelectCustom', BaseSelectCustom)
Vue.component('BaseDownload', BaseDownload)
Vue.component('BaseInputCustom', BaseInputCustom)
Vue.component('DragDropExcel', DragDropExcel)
Vue.component('BaseSelectNumber', BaseSelectNumber)
