import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  appId: process.env.VUE_APP_APP_ID,
}
// console.log(firebaseConfig)

// const firebaseConfig = {
//   apiKey: "AIzaSyDElHx8ArJN5leb-TEWQmoZ-ieSLSZ3Oqw",
//   authDomain: "renta-integracion-smart-prd.firebaseapp.com",
//   databaseURL: 'https://renta-integracion-smart-prd-default-rtdb.firebaseio.com',
//   projectId: "renta-integracion-smart-prd",
//   storageBucket: "renta-integracion-smart-prd.appspot.com",
//   appId: "1:241633389339:web:9738300016b928b076c1be"
// };

export default async () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig)

  const database = getDatabase(app)

  const storage = getStorage(app)

  return { database, storage }
}
