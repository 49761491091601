<template>
  <div class="w-full mb-4">
    <div
      class="w-full px-6 py-2 rounded-lg flex justify-between items-center bg-blue-light cursor-pointer"
      @click="toggleAccordion"
    >
      <p class="text-blue-dark text-left">
        <b>{{ item.title }}</b>
      </p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="w-full p-3">
      <p class="text-justify">{{ item.body }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      accordionOpen: false,
    }
  },
  methods: {
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen
    },
  },
}
</script>

<style></style>
