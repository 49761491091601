import Axios from 'axios'
import store from '../../store'

// Refactorize modules
import complaintsModule from './modules/complaints.module'
import usersModule from './modules/users.module'
import analyticsModule from './modules/analytics.module'
import companiesModule from './modules/companies.module'
import tariffsModule from './modules/tariffs.module'

const templateError = {
  response: {
    data: {
      data: 'Error desconocido',
      status: 0,
    },
  },
}

let axios = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

const api = {
  // Base URL
  defaults: {
    baseURL: process.env.VUE_APP_BASE_URL,
  },
  // REFACTORIZADO
  // User management
  users: usersModule(axios),
  complaints: complaintsModule(axios),
  analytics: analyticsModule(axios),
  companies: companiesModule(axios),
  tariffs: tariffsModule(axios),
  axios,
}

export default {
  install(Vue) {
    axios.interceptors.request.use(
      function (config) {
        config.headers['Content-Type'] = `application/json`
        if (store.getters['session/isAuthenticated']) {
          config.headers[
            'Authorization'
          ] = `Bearer ${store.state.session.user.accessToken}`
        }
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      },
      { synchronous: true }
    )

    // Incerteptor over response
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
      },
      async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (!error.response) {
          return Promise.reject(templateError)
        }
        return Promise.reject(error)
      }
    )
    Vue.prototype.$api = api
  },
}
