export default (axios) => ({
  getAnalytics(params) {
    return axios
      .get(`/api/analytics/report`, { params })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  downloadAnalytics() {
    return axios
      .get('/api/analytics/report/excel')
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
})
