<template>
  <div class="w-full mt-5 flex flex-col">
    <p
      class="w-full mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      v-if="!!label"
    >
      <b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span>
    </p>
    <div class="grid grid-cols-2 items-center">
      <!-- :checked="value == option.id? true : false" -->
      <label
        class="flex items-center mx-2 text-xs md:text-sm"
        :class="{ 'text-red-500': invalid }"
        v-for="(option, index) in radioOptions"
        :key="option.id"
      >
        <input
          class="mr-2"
          type="radio"
          :checked="value == option.id ? true : false"
          :id="id + index"
          :name="name"
          :value="value"
          :disabled="disabled"
          @change="$emit('input', option.id)"
        />
        {{ option.name }}
      </label>
    </div>
    <p v-if="invalid" class="mt-2 text-xs text-left text-red-500">
      Este campo es obligatorio
    </p>
  </div>
</template>

<script>
export default {
  props: [
    'name',
    'id',
    'value',
    'label',
    'radioOptions',
    'required',
    'disabled',
  ],
  data() {
    return {
      invalid: false,
    }
  },
  methods: {
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid
      }
    },
  },
  watch: {
    value: function () {
      if (this.value != '') {
        this.invalid = false
      }
    },
  },
}
</script>

<style></style>
