import usersRouter from './users.router'
import complaintsRouter from './complaints.router'
import analyticsRouter from './analytics.router'
import companiesRouter from './companies.router'
import tariffsRouter from './tariffs.router'

export default [
  {
    path: '/dashboard',
    component: () => import('@/layouts/DashboardLayout.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: () => import('@/views/dashboard'),
        meta: {
          title: 'Inicio',
          breadcrumb: [{ label: 'Inicio' }],
        },
      },
      {
        path: 'profile',
        component: () => import('@/views/dashboard/profile'),
        meta: {
          requiresAuth: true,
          title: 'Mi Perfil',
          breadcrumb: [{ label: 'Inicio', link: '/' }, { label: 'Perfil' }],
        },
      },
      {
        path: 'notifications',
        component: () => import('@/views/dashboard/notifications'),
        meta: {
          title: 'Notificaciones',
          breadcrumb: [
            { label: 'Inicio', link: '/' },
            { label: 'Notificaciones' },
          ],
        },
      },
      {
        path: 'sync',
        component: () => import('@/views/dashboard/sync'),
        meta: {
          title: 'Sincronizar',
          breadcrumb: [
            { label: 'Inicio', link: '/' },
            { label: 'Sincronizar' },
          ],
        },
      },
      ...analyticsRouter,
      ...usersRouter,
      ...complaintsRouter,
      ...companiesRouter,
      ...tariffsRouter,
    ],
  },
]
