<template>
  <div
    class="flex flex-col w-full h-auto mt-5 items-center justify-center text-center"
  >
    <label
      class="w-full text-left text-xs md:text-sm text-gray-500 text-opacity-80"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <div
      class="w-full px-12 py-5 mt-2 rounded-lg border-dashed border-2 border-gray-300"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        :id="id"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        :accept="
          accept
            ? accept
            : '.pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg,application/vnd.ms-outlook, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv'
        "
      />

      <label :for="id" class="block cursor-pointer">
        <span
          class="py-2 px-5 my-3 inline-block rounded-full bg-primary text-xs text-white"
          ><b>{{ buttonLabel }}</b></span
        >
        <div>
          <p class="text-sm text-gray-500">
            <b class="text-gray-400">archivos soportados</b><br />
            {{
              acceptText
                ? acceptText
                : 'pdf, jpg, jpeg, png, mp4, doc, docx, xls, xlsx, bmp, mp3, msg'
            }}
          </p>
        </div>
      </label>

      <ul class="mt-4" v-if="fileList" v-cloak>
        <li class="text-sm p-1" v-for="(file, i) in fileList" :key="i">
          {{ file.name }} - ({{ (file.size / (1024 * 1024)).toFixed(2) }} MB)
          <button
            class="ml-2 text-red-500"
            type="button"
            @click="remove(file)"
            title="Remove file"
          >
            Eliminar
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'label',
    'buttonLabel',
    'required',
    'id',
    'fileList',
    'accept',
    'acceptText',
  ],
  data() {
    return {}
  },
  methods: {
    onChange() {
      let newFileList = [...this.fileList]
      let files = [...this.$refs.file.files]
      files.forEach((item) => {
        if (
          item.type.includes('pdf') ||
          item.type.includes('jpg') ||
          item.type.includes('jpeg') ||
          item.type.includes('png') ||
          item.type.includes('mp4') ||
          item.type.includes('doc') ||
          item.type.includes('docx') ||
          item.type.includes(
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) ||
          item.type.includes('application/vnd.ms-excel') ||
          item.type.includes('csv') ||
          item.type.includes('application/vnd.ms-outlook') ||
          item.type.includes('mp3') ||
          item.type.includes('audio/mpeg')
        ) {
          newFileList.push(item)
        } else {
          // Validación extra para archivos msg
          if (
            item.name !== null &&
            item.name !== undefined &&
            item.name.endsWith('.msg')
          ) {
            newFileList.push(item)
          } else {
            this.$showNotification(
              'error',
              'El formato del archivo no es válido'
            )
          }
        }
      })
      this.$emit('files-handler', newFileList)
      this.$refs.file.value = ''
    },
    remove(file) {
      let newFileList = this.fileList.filter((f) => f != file)
      this.$emit('files-handler', newFileList)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-blue-light')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-blue-light')
      }
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-blue-light')
    },
    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-blue-light')
    },
  },
}
</script>

<style></style>
