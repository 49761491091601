import {
  getAuth,
  signOut,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth'

const getDefaultState = () => {
  return {
    access: null,
    user: null,
    me: null,
    showLoader: false,
  }
}

const state = getDefaultState

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_ME(state, me) {
    state.me = me
  },
  TOGGLE_LOADER(state, showLoader) {
    state.showLoader = showLoader
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}
const actions = {
  async login({ dispatch }, credentials) {
    const auth = getAuth()
    if (!credentials.remember) {
      await setPersistence(auth, browserSessionPersistence)
    }
    const { user } = await signInWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    ).catch((e) => {
      console.log(e)
    })
    if (user) {
      const response = await dispatch('loadUser', user)
      return response
    }
  },
  async loadUser({ commit, dispatch, state }, user) {
    commit('TOGGLE_LOADER', true)
    if (!user) {
      commit('TOGGLE_LOADER', false)
      return
    }
    commit('SET_USER', user)
    let { error, data } = await this._vm.$api.users.getUser(user.uid)

    if (error) {
      this.logout()
    }

    if (data) {
      commit('SET_ME', data)
    }
    dispatch(
      'tariffs/initTariffsStore',
      { access: state.access },
      { root: true }
    )
    dispatch('form/initFormStore', { access: state.access }, { root: true })

    commit('TOGGLE_LOADER', false)
    return 'success'
  },
  async logout() {
    const auth = getAuth()
    const response = await signOut(auth)
    if (response) {
      return 'logout'
    }
  },
}
const getters = {
  isLoggedIn(state) {
    return !!state.me
  },
  isAuthenticated(state) {
    return !!state.user
  },
  isSuper(state) {
    return state.me?.role === 'GOTU' ? true : false
  },
  getModules(state) {
    return state.me?.modules || []
  },
}

const session = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default session
