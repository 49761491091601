import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import initFirebase from './firebase/init'

// Plugins
import api from './plugins/api/api'
import validateService from './plugins/validate'
import firebaseLinkService from './plugins/firebaseLink'
import luxonService from './plugins/luxon'
import notificationsService from './plugins/notifications'
import './plugins/globalComponents'
import './plugins/antdComponents'

// CSS
import './assets/styles/main.css'

initFirebase()

Vue.config.productionTip = false
Vue.use(router)
Vue.use(api)
Vue.use(validateService)
Vue.use(firebaseLinkService)
Vue.use(luxonService)
Vue.use(notificationsService)

let app
const auth = getAuth()

onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      store,
      router,
      render: (h) => h(App),
      mounted() {
        document.onreadystatechange = () => {
          if (document.readyState == 'complete') {
            this.$store.dispatch('initStore')
          }
        }
      },
    }).$mount('#app')
  }
})
