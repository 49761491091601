<template>
  <div
    class="w-11/12 px-6 py-6 md:px-10 md:py-10 bg-white rounded-2xl shadow-lg"
  >
    <slot> </slot>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style></style>
