export default [
  {
    path: 'users',
    component: () => import('@/views/dashboard/users/'),
    meta: {
      module: 'users',
      title: 'Gestión de usuario',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de usuarios' },
      ],
    },
  },
  {
    path: 'users/user/',
    component: () => import('@/views/dashboard/users/add'),
    meta: {
      module: 'users',
      title: 'Crear usuario',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de usuarios', link: '/dashboard/users' },
        { label: 'Crear Usuario' },
      ],
    },
  },
  {
    path: 'users/:user_id/edit',
    component: () => import('@/views/dashboard/users/edit'),
    meta: {
      module: 'users',
      title: 'Editar usuario',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de usuarios', link: '/dashboard/users' },
        { label: 'Editar Usuario' },
      ],
    },
  },
]
