export default [
  {
    path: 'tariffs',
    component: () => import('@/views/dashboard/tariffs/'),
    meta: {
      title: 'Tarifarios',
      breadcrumb: [{ label: 'Inicio', link: '/' }, { label: 'Tarifario' }],
    },
  },
  {
    path: 'tariffs/create',
    component: () => import('@/views/dashboard/tariffs/create'),
    meta: {
      title: 'Tarifario',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Tarifarios', link: '/dashboard/tariffs' },
        { label: 'Crear' },
      ],
    },
  },
  {
    path: 'tariffs/:tariff_id/',
    name: 'TariffDetails',
    component: () => import('@/views/dashboard/tariffs/[id]'),
    meta: {
      title: 'Tarifario',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Tarifarios', link: '/dashboard/tariffs' },
        { label: 'Ver' },
      ],
    },
  },
  {
    path: 'tariffs/:tariff_id/edit',
    name: 'TariffEdit',
    component: () => import('@/views/dashboard/tariffs/[id]/edit'),
    meta: {
      title: 'Tarifario',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Tarifarios', link: '/dashboard/tariffs' },
        { label: 'Editar' },
      ],
    },
  },
]
