export default [
  {
    path: 'analytics',
    component: () => import('@/views/dashboard/analytics/index.vue'),
    meta: {
      title: 'Análitica e indicadores',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Análitica e indicadores' },
      ],
    },
  },
]
