import complaintRouter from './complaint.router'
export default [
  {
    path: 'complaints',
    component: () => import('@/views/dashboard/complaints/'),
    meta: {
      title: 'Gestión de quejas',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de quejas' },
      ],
    },
  },
  // COMPLAINTS UPLOAD
  {
    path: 'complaints/upload/',
    component: () => import('@/views/dashboard/complaints/upload'),
    meta: {
      title: 'Carga masiva de quejas',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Carga masiva de quejas' },
      ],
    },
  },
  ...complaintRouter,
]
