<template>
  <div
    class="fixed top-0 left-0 h-screen w-full flex justify-center items-center z-50 bg-black bg-opacity-10"
  >
    <div class="w-11/12 max-w-xl py-7 px-5 rounded-xl bg-white border-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  mounted() {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.width = '100%'
  },
  beforeDestroy() {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  },
}
</script>

<style></style>
