import { getStorage, getDownloadURL, ref, uploadBytes } from 'firebase/storage'

let getLink = async (link) => {
  const storage = getStorage()
  let response = await getDownloadURL(ref(storage, link))
  return response
}

const uploadFileFirebase = async (path, file) => {
  const fileName = `file_${file.lastModified}_` + file.name

  const newFile = new File([file], fileName)

  const storage = getStorage()
  const storageRef = ref(storage, `${path}${newFile.name}`)

  const snapshot = await uploadBytes(storageRef, newFile)

  return snapshot.ref
}

export default {
  install(Vue) {
    Vue.prototype.$getFirebaseLink = getLink
    Vue.prototype.$uploadFileFirebase = uploadFileFirebase
  },
}
