export default [
  {
    path: 'companies',
    component: () => import('@/views/dashboard/companies/'),
    meta: {
      title: 'Gestión de entidades',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de entidades' },
      ],
    },
  },
  {
    path: 'companies/company/',
    component: () => import('@/views/dashboard/companies/add'),
    meta: {
      title: 'Crear entidad',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de entidades', link: '/dashboard/companies' },
        { label: 'Crear entidad' },
      ],
    },
  },
  {
    path: 'companies/:company_id/edit',
    component: () => import('@/views/dashboard/companies/edit'),
    meta: {
      title: 'Editar entidad',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de entidades', link: '/dashboard/companies' },
        { label: 'Editar entidad' },
      ],
    },
  },
]
