<template>
  <div class="h-full w-full mt-5 text-left flex flex-col">
    <label
      :for="id"
      class="text-xs md:text-xs text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <input
      class="w-full mt-2 border rounded-lg text-xs md:text-sm"
      :class="classObject"
      type="number"
      :id="id"
      :name="id"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keyup="checkInput"
      autocomplete="on"
      :disabled="disabled"
    />
    <p v-if="invalid" class="mt-2 text-xs text-red-500">
      {{ feedback ? feedback : 'Este campo es obligatorio' }}
    </p>
    <p v-if="messageError" class="mt-2 text-xs text-left text-red-500">
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'type',
    'label',
    'placeholder',
    'value',
    'disabled',
    'danger',
    'required',
  ],
  data() {
    return {
      feedback: '',
      invalid: false,
      messageError: '',
    }
  },
  mounted() {},
  methods: {
    checkInput() {
      this.messageError = ''
      if (this.value == '' && this.required) {
        this.invalid = true
        this.feedback = 'Este campo es obligatorio'
      } else {
        this.invalid = false
        this.feedback = ''
      }
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid
      }
    },
  },
  computed: {
    classObject: function () {
      let classes = ['border-gray-300', 'placeholder-gray-400']
      if (this.danger || this.invalid) {
        classes = ['border-red-500', 'placeholder-red-500']
      }
      return classes
    },
  },
}
</script>

<style></style>
