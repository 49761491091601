<template>
  <div class="h-full w-full mt-5 text-left flex flex-col">
    <label
      :for="id"
      class="mb-2 text-xs md:text-xs text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <a-date-picker
      @change="onChange"
      placeholder="Seleccionar"
      format="YYYY-MM-DD"
    />
    <p v-if="invalid" class="mt-2 text-xs text-left text-red-500">
      Este campo es obligatorio
    </p>
    <p v-if="messageError" class="mt-2 text-xs text-red-500">
      {{ messageError }}
    </p>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: [
    'id',
    'type',
    'label',
    'placeholder',
    'value',
    'disabled',
    'danger',
    'required',
  ],
  data() {
    return {
      momentValue: '',
      invalid: false,
      messageError: '',
    }
  },
  mounted() {},
  methods: {
    onChange(date, dateString) {
      this.messageError = ''
      this.$emit('input', dateString)
    },
    setInvalid(invalid) {
      if (this.required) {
        this.feedback = 'El campo no debe ser vacio'
        this.invalid = invalid
      }
    },
  },
  computed: {
    classObject: function () {
      let classes = ['border-gray-300', 'placeholder-gray-400']
      if (this.danger || this.invalid) {
        classes = ['border-red-600', 'placeholder-red-600']
      }
      return classes
    },
  },
  watch: {
    value: function () {
      this.momentValue = moment().set(this.value)
    },
  },
}
</script>

<style></style>
