import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import sessionModule from './modules/session.store'
import formModule from './modules/form.store'
import tariffsModule from './modules/tariffs.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session: sessionModule,
    form: formModule,
    tariffs: tariffsModule,
  },
  state: {},
  mutations: {},
  actions: {
    initStore({ dispatch }) {
      dispatch('form/loadFormOptions')
    },
  },
})
