const url = '/api/companies'
export default (axios) => ({
  getCompanies(params) {
    return axios
      .get(url, { params })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getCompany(id) {
    return axios
      .get(`${url}/${id}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  createCompany(data) {
    return axios
      .post(`${url}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateCompany(id, data) {
    return axios
      .put(`${url}/${id}`, data)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  deleteCompany(id) {
    return axios
      .delete(`${url}/${id}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getCompanySector() {
    return axios
      .get(`${url}/sectors`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
})
