import { notification } from 'ant-design-vue'
export default {
  install(Vue) {
    Vue.prototype.$showNotification = (type, msg) => {
      notification[type]({
        message: 'Resultado',
        description: msg,
        onClick: () => {},
      })
    }
  },
}
