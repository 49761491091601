<template>
  <transition name="fade-in">
    <div
      v-if="showLoader"
      class="absolute h-screen w-screen z-50 flex justify-center items-center bg-white"
    >
      <svg
        width="132"
        height="32"
        viewBox="0 0 132 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="overflow: visible"
      >
        <path
          id="square"
          d="M131.206 0.440002V31.5622H97.1552V0.440002H131.206Z"
          fill="#F17100"
        />
        <path
          id="circle"
          d="M68.0536 31.5695C76.6498 31.5695 83.6184 24.6009 83.6184 16.0047C83.6184 7.40858 76.6498 0.440002 68.0536 0.440002C59.4575 0.440002 52.4889 7.40858 52.4889 16.0047C52.4889 24.6009 59.4575 31.5695 68.0536 31.5695Z"
          fill="#4CFD8A"
        />
        <path
          id="rectangle"
          d="M16.3591 0.440002C12.231 0.440002 8.27208 2.07987 5.35312 4.99882C2.43416 7.91778 0.794312 11.8767 0.794312 16.0048C0.795288 19.3243 1.85706 22.5568 3.82472 25.2303C5.79237 27.9039 8.56291 29.8787 11.7321 30.8667C12.2133 31.0207 12.702 31.1502 13.1963 31.2547C14.2277 31.4638 15.2774 31.5692 16.3298 31.5695H43.03V0.440002H16.3591Z"
          fill="#00CAE6"
        />
      </svg>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('session', ['showLoader']),
  },
}
</script>

<style>
@keyframes rectangleAnimation {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  40% {
    opacity: 0;
    transform: translateY(-30px);
  }
  60% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

#rectangle {
  animation: rectangleAnimation 3s ease-in-out 0s infinite normal both;
}
#circle {
  animation: rectangleAnimation 3s ease-in-out 0.2s infinite normal both;
}
#square {
  animation: rectangleAnimation 3s ease-in-out 0.4s infinite normal both;
}
</style>
