const url = '/api/complaints'
export default (axios) => ({
  getComplaints(params) {
    return axios
      .get(url, { params })
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getComplaintById(id) {
    return axios
      .get(`${url}/${id}`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getComplaintsByPage(query) {
    return axios
      .get(url + '?' + query)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getComplaintExtension(complaint_id) {
    return axios
      .get(url + `/${complaint_id}/extension`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  createComplaint(form, files) {
    let formData = new FormData()
    let entries = Object.entries(form)
    Object.keys(form).map((key) => {
      form[key] = form[key] + ''
    })
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1])
    })
    files.forEach((file, index) => {
      formData.append(`file-${index}`, file)
    })
    return axios
      .post(url, formData)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  updateComplaint(complaint_id, form, files) {
    let formData = new FormData()
    let entries = Object.entries(form)
    Object.keys(form).map((key) => {
      form[key] = form[key] + ''
    })
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1])
    })
    files.forEach((file, index) => {
      formData.append(`file-${index}`, file)
    })
    return axios
      .put(`${url}/${complaint_id}`, formData)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  uploadComplaintFiles(form, files) {
    let formData = new FormData()
    let entries = Object.entries(form)
    Object.keys(form).map((key) => {
      form[key] = form[key] + ''
    })
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1])
    })
    files.forEach((file, index) => {
      formData.append(`file-${index}`, file)
    })
    return axios
      .post('/api/upload', formData)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  searchComplaints: (params) => {
    return axios
      .post(`/api/search/complaints`, params)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  uploadComplaintsMassive(files) {
    let formData = new FormData()
    formData.append('massive', 'Cargue masivo de quejas')
    files.forEach((file, index) => {
      formData.append(`file-${index}`, file)
    })
    return axios
      .post('/api/upload/massive', formData)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  getComplaintHistory(id) {
    return axios
      .get(`${url}/${id}/history`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
  downloadComplaintsExcel() {
    return axios
      .get(`${url}/excel`)
      .then(({ data }) => data)
      .catch(({ response }) => response.data)
  },
})
