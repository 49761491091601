export default [
  {
    name: 'ComplaintRegistration',
    path: 'complaints/complaint/',
    props: true,
    component: () =>
      import('@/views/dashboard/complaints/complaint/create.vue'),
    meta: {
      title: 'Radicar una nueva queja',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Radicar nueva queja' },
        { label: 'Formulario nueva queja' },
      ],
    },
  },
  {
    path: 'complaints/:complaint_id/',
    name: 'ComplaintDetails',
    props: true,
    component: () =>
      import('@/views/dashboard/complaints/complaint/details.vue'),
    meta: {
      title: 'Detalle de queja',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de quejas', link: '/dashboard/complaints' },
        { label: 'Detalle de queja' },
      ],
    },
  },
  {
    path: 'complaints/:complaint_id/history',
    component: () => import('@/views/dashboard/complaints/complaint/history/'),
    meta: {
      title: 'Histórico de queja',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de quejas', link: '/dashboard/complaints' },
        { label: 'Histórico' },
      ],
    },
  },
  {
    path: 'complaints/:complaint_id/response',
    component: () =>
      import('@/views/dashboard/complaints/complaint/response.vue'),
    meta: {
      title: 'Respuesta queja',
      breadcrumb: [
        { label: 'Inicio', link: '/' },
        { label: 'Gestión de quejas', link: '/dashboard/complaints' },
        { label: 'Detalle de queja' },
        { label: 'Respuesta ante queja' },
      ],
    },
  },
]
