<template>
  <div id="app">
    <Loader />
    <router-view />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Loader from '@/components/UI/Loader'
export default {
  components: {
    Loader,
  },
  computed: {
    ...mapState('session', ['me']),
  },
  watch: {
    me(value) {
      if (!value) return
      if (!value.company?.primary_color && !value.company?.secondary_color)
        return
      document.documentElement.style.setProperty(
        '--primary-color',
        `${value.company.primary_color}`
      )
      document.documentElement.style.setProperty(
        '--secondary-color',
        `${value.company.secondary_color}`
      )
    },
  },
}
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
