<template>
  <div class="relative w-full mt-5 flex flex-col border-0 p-0">
    <label
      :for="id"
      class="mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <a-select
      show-search
      option-filter-prop="children"
      class="text-left"
      :class="{ 'border-red-500': invalid }"
      :filter-option="filterOption"
      :value="`${modelValue}`"
      @change="handleChange"
      :disabled="disabled"
      :getPopupContainer="(trigger) => trigger.parentNode"
    >
      <a-select-option :value="''" class="text-gray-400 font-normal text-left">
        Seleccionar
      </a-select-option>
      <a-select-option
        data-bs-toggle="tooltip"
        :title="option.name"
        v-for="option in selectOptions"
        class="text-left"
        :value="`${option.id}`"
        :key="option.id"
      >
        {{ option.name }}
      </a-select-option>
    </a-select>
    <p v-if="invalid" class="mt-2 text-left text-xs text-red-500">
      Este campo es obligatorio
    </p>
    <p v-if="messageError" class="mt-2 text-xs text-left text-red-500">
      {{ messageError }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['id', 'selectOptions', 'label', 'required', 'value', 'disabled'],
  data() {
    return {
      invalid: false,
      messageError: '',
    }
  },
  computed: {
    modelValue: {
      set() {
        this.modelValue = this.value
      },
      get() {
        if (this.value === '' || this.value === null) {
          return ''
        } else {
          return this.value
        }
      },
    },
  },
  methods: {
    handleChange(value) {
      this.messageError = ''
      this.$emit('input', value)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid
      }
    },
  },
  watch: {
    value: function (value) {
      if (value == '' && this.required) {
        this.invalid = true
      } else {
        this.invalid = false
      }
    },
  },
}
</script>

<style></style>
