<template>
  <div class="my-2 flex justify-between items-center gap-3">
    <div
      class="p-3 w-full flex items-center gap-3 border border-gray-300 rounded-lg overflow-hidden"
    >
      <a-icon type="file-text" />
      <p class="text-xs text-gray-500 truncate">{{ value }}</p>
    </div>
    <span
      class="w-16 flex-shrink-0 pt-2 flex justify-center items-center self-stretch rounded-lg text-white bg-secondary hover:bg-gray-300 cursor-pointer"
      @click="onDownload()"
    >
      <a-icon type="download" class="text-xl" />
    </span>
  </div>
</template>

<script>
export default {
  props: ['id', 'label', 'value', 'url_download'],
  data() {
    return {
      invalid: false,
      feedback: '',
      url: '',
    }
  },
  watch: {
    url_download: {
      async handler(value) {
        if (!value) return
        await this.$getFirebaseLink(value).then(
          (response) => (this.url = response)
        )
      },
      immediate: true,
    },
  },
  methods: {
    onDownload() {
      let a = document.createElement('a')
      a.href = this.url
      a.target = '_blank'
      a.download = this.value
      a.click()
    },
  },
  computed: {},
}
</script>

<style></style>
